<footer class="footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 mt-3">
                <h4>BANDEJA DE FACTURAS</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <li><a href="#">Inicio</a></li>
                        <li><a style="cursor: pointer;" (click)="scroll('features')" >¿Por qué?</a></li>
                        <li><a style="cursor: pointer;" (click)="scroll('services')">Características</a></li>
                        <!-- <li><a href="#">Tarifas</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 mt-3">
                <h4>Información</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                     
                        <li><a style="cursor: pointer;" (click)="scroll('pricing')">Precios</a></li>
                        <li><a href="https://www.diapli.com/?page_id=10777">Política de privacidad</a></li>
                        <!-- <li><a href="#">Blog</a></li> -->
                        <!-- <li><a href="#">Bookmarks</a></li> -->
                    </ul>
                </div>
            </div>
            <div class="col-lg-4 mt-3">
                <h4>Soporte</h4>
                <div class="text-muted mt-4">
                    <ul class="list-unstyled footer-list">
                        <!-- <li><a href="">FAQ</a></li> -->
                        <li><a style="cursor: pointer;" (click)="scroll('contact')">Contacto</a></li>
                        <!-- <li><a href="">Disscusion</a></li> -->
                    </ul>
                </div>
            </div>
            <!-- <div class="col-lg-3 mt-4">
                <h4>Suscríbete</h4>
                <div class="text-muted mt-4">
                    <p>In an ideal world this text wouldn’t exist, a client would acknowledge the importance of having web copy before the design starts.</p>
                </div>
                <form class="form subscribe">
                    <input placeholder="Email" class="form-control" required>
                    <a href="#" class="submit"><i class="pe-7s-paper-plane"></i></a>
                </form>
            </div> -->
        </div>
    </div>
</footer>
<!--END FOOTER-->

<!--START FOOTER ALTER-->
<div class="footer-alt">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="float-start pull-none">
                    <p class="copy-rights text-muted mb-3 mb-sm-0">{{ cur_year }} © BDF - Diapli</p>
                </div>
                <div class="float-end pull-none ">
                    <ul class="list-inline d-flex flex-wrap social m-0">
                        <!-- <li class="list-inline-item"><a href="" class="social-icon ml-1"><i class="mdi mdi-facebook"></i></a></li>
                        <li class="list-inline-item"><a href="" class="social-icon ml-1"><i class="mdi mdi-twitter"></i></a></li> -->
                        <li class="list-inline-item"><a href="https://es.linkedin.com/company/diapli-software" target="_blank" class="social-icon ml-1"><i class="mdi mdi-linkedin"></i></a></li>

                        <li class="list-inline-item"><a href="https://www.diapli.com/" target="_blank" class="social-icon ml-1"><i class="mdi mdi-web"></i></a></li>
                    </ul>
                </div>
                <div class="clearfix"></div>
            </div>
        </div>
    </div>
</div>
<!--START FOOTER ALTER-->

<!-- Style switcher -->
<!-- <div id="style-switcher" [style.left] = "switch == 0 ? '0px' : '-189px'">
    <div>
        <h3>Select your color</h3>
        <ul class="pattern">
            <li>
                <a (click)="onChangeColor('cyan')" class="color1"></a>
            </li>
            <li>
                <a (click)="onChangeColor('red')" class="color2"></a>
            </li>
            <li>
                <a (click)="onChangeColor('green')" class="color3"></a>
            </li>
            <li>
                <a  (click)="onChangeColor('pink')"class="color4"></a>
            </li>
            <li>
                <a  (click)="onChangeColor('blue')"class="color5" ></a>
            </li>
            <li>
                <a (click)="onChangeColor('purple')" class="color6"></a>
            </li>  
            <li>
                <a  (click)="onChangeColor('orange')"class="color7"></a>
            </li>
            <li>
                <a (click)="onChangeColor('yellow')" class="color8"></a>
            </li>                   
        </ul>
    </div>
    <div class="bottom">
        <a (click)="onChangeSwitch()" class="settings rounded-right"><i class="mdi mdi-settings mdi-spin"></i></a>
    </div>
</div> -->