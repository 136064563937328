<div id="theme_id" class="theme-red">
<div class="account-home-btn d-none d-sm-block">
  <a routerLink="/" class="text-white"><i class="mdi mdi-home h1"></i></a>
</div>

<section class="vh-100">
  <div class="display-table">
      <div class="display-table-cell">
          <div class="container">
              <div class="row justify-content-center">
                  <div class="col-lg-5">
                      <div class="card account-card">
                          <div class="card-body">
                              <div class="text-center mt-3">
                                  <h3 class="font-weight-bold"><a routerLink="/" class="text-dark text-uppercase account-pages-logo"><i class="mdi mdi-alien"></i>Hiric</a></h3>
                                  <p class="text-muted">Sign in to continue to Hiric.</p>
                              </div>
                              <div class="p-4">
                                  <form action="#">
                                      <div class="form-group">
                                          <label for="username">Username</label>
                                          <input type="text" class="form-control" id="username" placeholder="Enter username">
                                      </div>

                                      <div class="form-group">
                                          <label for="userpassword">Password</label>
                                          <input type="password" class="form-control" id="userpassword" placeholder="Enter password">
                                      </div>

                                      <div class="form-check">
                                          <input type="checkbox" class="form-check-input" id="customControlInline">
                                          <label class="form-check-label" for="customControlInline">Remember me</label>
                                      </div>

                                      <div class="d-grid mt-3">
                                            <button type="submit" class="btn btn-primary">Log In</button>
                                      </div>

                                      <div class="mt-4 mb-0 text-center">
                                          <a routerLink="/password-forget" class="text-dark"><i class="mdi mdi-lock"></i> Forgot your password?</a>
                                      </div>
                                  </form>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </div>
      </div>
  </div>
</section>
</div>